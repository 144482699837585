import React from "react";
import Banner from "./banner/index.js";
import SearchAnswer from "./searchAnswer/index.js";
import Expert from "./Expert/index.js";
import Gallerysection from "./gallerysection/index.js";
import PopularQuestions from "./popularQuestions/index.js";
import { useStaticQuery, graphql } from "gatsby";
import Meet from "./meet/index.js";
export default function MainHome() {
  const data = useStaticQuery(graphql`
    {
      allCosmicjsPopularQuestions {
        nodes {
          metadata {
              answer
              question
              user
              id
              user_avatar {
                  url
              }
              user_affiliation
              user_location
              user_name
              user_specialities
              subtopic_icon
              meta_image {
                  url
              }
          }
        }
      }
      allCosmicjsHome {
        edges {
          node {
            slug

            metadata {
              content
              details
              heading
              description
              mobile_app_details {
                data {
                  title
                  content
                  media
                }
              }
              story_link_button_text
              experts_information {
                data {
                  img
                  title
                  detial
                }
              }
              name
              story_link
              sub_heading
              background_image {
                url
              }
            }
          }
        }
      }
    }
  `);

  var content_header;
  var content_available_expert;
  var content_create_account;
  var content_stories;
  var content_mobile_app;
  var content_question_trending;
  var content_meet;

  data.allCosmicjsHome.edges.map((x, y) => {
    switch (x.node.slug) {
      case "heading":
        content_header = x.node.metadata;
        break;

      case "available-experts":
        content_available_expert = x.node.metadata;
        break;

      case "create-account":
        content_create_account = x.node.metadata;
        break;

      case "stories":
        content_stories = x.node.metadata;
        break;

      case "mobile-app":
        content_mobile_app = x.node.metadata;
        break;
      case "expert-home":
        content_meet = x.node.metadata;
        break;
      case "trending-questions":
        content_question_trending = x.node.metadata;
        break;
    }
  });

  return (
    <div className="mainHome">
      <Banner
        heading={content_header.heading}
        subheading={content_header.sub_heading}
      />
      <Meet content_meet={content_meet} />

      <PopularQuestions
        heading="POPULAR"
        content={data.allCosmicjsPopularQuestions.nodes}
      />
      <Expert
        heading={content_available_expert.heading}
        content={content_available_expert.experts_information.data}
      />

      <Gallerysection
        heading={content_create_account.heading}
        detail={content_create_account.details}
        name={content_stories.name}
        storydetail={content_stories.content}
        storybuttonLink={content_stories.story_link}
        background={content_create_account.background_image.url}
        storybuttontext={content_stories.story_link_button_text}
      />
      <SearchAnswer content={content_mobile_app.mobile_app_details.data} />
    </div>
  );
}
