import React from "react";
import { Fade } from "react-awesome-reveal";

export default function Tabdata({ title, data, media }) {
  return (
    <div className="tabdata">
      <div className="first">
        <Fade direction="top" duration={500}>
          <div>
            <h2>{title}</h2>
            <p>{data}</p>
          </div>
        </Fade>

        {/*   <div className="img-section">
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/fertilityanswers/id1418650176"
          >
            <img src={apple} />
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.fertilityanswers"
          >
            <img src={playstore} />
          </a>
        </div> */}
      </div>
      <div className="mobile-down">
        <img className="mobile" alt="mobile app" src={media} />
      </div>
    </div>
  );
}
