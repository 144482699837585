import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";

import VideoModal from "../../../VideoModal/VideoModal";
import arrow from "../assets/images/arrow.svg";

export default function Single({
  name,
  accountButton,
  content,
  accountButtonlink,
}) {
  const videoId = accountButtonlink.split("watch?v=")[1];
  const [videoModalVisible, setVideoModalVisible] = useState(false);

  return (
    <div className="Single-img">
      <Fade direction="top" duration={500}>
        <div className="abs-path">
          <h3>{name}</h3>
          <h5>{content}</h5>
          <button
            className="watch-button"
            onClick={() => setVideoModalVisible(true)}
          >
            {accountButton}
            <img src={arrow} alt="arrow" aria-hidden="true" />
          </button>
          <VideoModal
            youtubeUrl={videoId}
            show={videoModalVisible}
            onHide={() => setVideoModalVisible(false)}
          />
        </div>
      </Fade>
    </div>
  );
}
