import React from "react";
import bg from "./assets/bg.svg";
import smallbg from "./assets/smallbg.svg";
import member3 from "./assets/member3.png";
import member2 from "./assets/member2.png";
import arrow from "./assets/arrow.svg";
import "./assets/style.scss";
import { Link } from "gatsby";
import Plx from "react-plx";
import createParallaxConfig from "../../layout/createParallaxConfig";

export default function Meet({ content_meet }) {
  return (
    <div className="Meet">
      <div className="content">
        <div className="top-content">
          <h3
            dangerouslySetInnerHTML={{
              __html: content_meet.heading,
            }}
          ></h3>
        </div>
        <div className="inner-meet">
          <div className="simple-content">
            <span
              dangerouslySetInnerHTML={{
                __html: content_meet.description,
              }}
            ></span>
            <Link to="/partners">
              <h4>
                Meet our Partners <img src={arrow} alt="" />{" "}
              </h4>
            </Link>
          </div>
        </div>
        <div className="img-sec">
          <div className="innerwidth-set">
            <div className="first-img">
              <img className="desktop-vf" src={bg} alt="experts answering illustration" />
              <img className="mobile-vf" src={smallbg} alt="experts answering illustration" />
            </div>
            <Link to="/partners/member?id=hardcoded">
              <Plx
                className="second-img"
                parallaxData={createParallaxConfig({
                  start: ".inner-meet",
                  distance: 250,
                })}
              >
                <img src={member2} alt="Expert - Dr Barbieri" />
                <h5>Dr. Barbieri, MD, FACOG,</h5>
                <h6>Reproductive & Infertility Specialist</h6>
              </Plx>
            </Link>
            <Link to="/partners/profile?id=34626">
              <Plx
                className="third-img"
                parallaxData={createParallaxConfig({
                  start: ".inner-meet",
                  distance: 150,
                })}
              >
                <img src={member3} alt="Expert - Dr. Raquel Hammonds" />
                <h5>Dr. Raquel Hammonds, NMD, MPH</h5>
                <h6>REI, Nutritionist</h6>
              </Plx>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
