import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import Home from "../components/Home/index.js";
import { watchloading } from "../state/app.js";
import { useDispatch, useSelector } from "react-redux";
import "../components/layout/assets/css/loader.scss";
const IndexPage = ({ location }) => {
  const loadingstatus = useSelector((state) => state.app.loading);

  const dispatch = useDispatch();
  const [isloading, setIsloading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
      dispatch(watchloading(false));
    }, 1000);
  }, []);
  return (
    <>
      {isloading && loadingstatus ? (
        <LoadingOverlay active={isloading}>
          <div id="preloader">
            <div id="loader"></div>
          </div>
        </LoadingOverlay>
      ) : (
          <Layout activepage="Home-page">
            <SEO title="Answers to all of your Fertility Questions" />
            {location.pathname == "/" && <Home />}
          </Layout>
        )}
    </>
  );
};

export default IndexPage;
