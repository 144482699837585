import React, { useEffect, useState } from "react";
import "./assets/css/style.scss";
import { Row, Col } from "react-bootstrap";
import Orientation from "./Orientation/orientation.js";
import SupportImg from "./supportImg/supportImg.js";
import { Fade } from "react-awesome-reveal";

export default function Banner({ subheading, heading }) {
  return (
    <>
      <div className="banner">
        <div className="content">
          <Row>
            <Col md={12} xl={5}>
              <Fade direction="top">
                <div>
                  <Orientation heading={heading} subheading={subheading} />
                </div>
              </Fade>
            </Col>
            <Col md={12} xl={7}>
              <Fade direction="right">
                <div>
                  <SupportImg />
                </div>
              </Fade>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
