import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "./assets/css/style.scss";
import ExpertInfo from "./expertInfo/expertInfo.js";

import { Fade } from "react-awesome-reveal";

export default function Expert({ content, heading }) {
  return (
    <div className="Expert">
      <div className="content">
        <Row>
          <Col xs={12}>
            <h2>{heading}</h2>
          </Col>

          {content.map((data, index) => {
            return (
              <Col xs={12} sm={4}>
                <Fade direction="top" delay={200 * (index + 1)}>
                  <div>
                    <ExpertInfo
                      title={data.title}
                      img={data.img}
                      info={data.detial}
                    />
                  </div>
                </Fade>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
}
