import React, { useState } from "react";
import Choose from "../../../layout/chooseRole/index.js";
import { SocialBox } from "../../../layout/menu/menu.js";
export default function Dual({ heading, details, background }) {
  const [choosebox, setchoosebox] = useState(false);
  return (
    <>
      <div className="dual-img">
        <img src={background} alt="gallery image" className="plain-img" />
        <div className="path-text">
          <h2>{heading}</h2>
          <p>{details}</p>

          {/* <a>
            {" "}
            <button
              onClick={() => {
                setchoosebox(true);
              }}
            >
              {loginText}
            </button>
          </a> */}
          <SocialBox />
        </div>
      </div>
      {choosebox && <Choose status={choosebox} setchoosebox={setchoosebox} />}
    </>
  );
}
