import React, { useState } from "react";
import applestore from "../assets/images/applestore.png";
import playstore from "../assets/images/playstore.png";

import mobile from "../assets/images/mobiles.png";
import Choose from "../../../layout/chooseRole/index.js";
export default function Orientation({ heading, subheading }) {
  const [choosebox, setchoosebox] = useState(false);

  return (
    <>
      <div className="intro-text">
        <h1>{heading}</h1>
        <h2>{subheading}</h2>
        <img src={mobile} className="dsiplayforsmall" alt="fertility answers mobile app" />
        <div className="buttons-all">
          {/*  <button
            onClick={() => {
              setchoosebox(true);
            }}
          >
            Create Account
          </button>

          <span>OR</span> */}
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/fertilityanswers/id1418650176"
          >
            <img src={applestore} alt="Get the app on Apple App store" />
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.fertilityanswers"
          >
            <img src={playstore} alt="Get the app on Play Store" />
          </a>
        </div>
      </div>
      {choosebox && <Choose status={choosebox} setchoosebox={setchoosebox} />}
    </>
  );
}
