import React from "react";
import Plx from "react-plx";
import desktop from "../assets/images/desktops.png";
import mobile from "../assets/images/mobiles.png";
import createParallaxConfig from '../../../layout/createParallaxConfig';

export default function SupportImg() {
  return (
    <div className="supporBannerImg">
      <Plx parallaxData={createParallaxConfig({ distance: 400 })}>
        <img src={desktop} alt="Fertility Answers Web Application" className="desktop" />
      </Plx>
      <Plx parallaxData={createParallaxConfig({ distance: 200 })} className="mobile">
        <img src={mobile} alt="Fertility Answers Mobile Application" />
      </Plx>
    </div>
  );
}
