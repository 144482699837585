import React from "react";
import { Row, Col } from "react-bootstrap";
import "./assets/css/style.scss";
import AllTabs from "./tabs/allTabs.js";
import { Fade } from "react-awesome-reveal";

export default function SearchAnswer({ content }) {
  return (
    <div className="answerSolution ">
      <div className="content">
        <Row>
          <Col xs={12}>
            <Fade direction="top" duration={500}>
              <h3>The MOBILE APP</h3>
            </Fade>

            <AllTabs content={content} />
          </Col>
        </Row>
      </div>
    </div>
  );
}
